<template>
  <div id="ListeDemande">
    <v-container>
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-card class="pa-3" outlined tile>
            <v-card-text>
              <v-col>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      item-value="value"
                      item-text="name"
                      :items="demandeStatus"
                      label="Status de la demande"
                      v-model="statutFiltrage"
                      clearable
                      chips
                      @input="onStatusChange"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" style="margin-top: 10px">
                    <hotel-date-picker
                      style="opacity: 90%; z-index: 2"
                      :halfDay="true"
                      :displayClearButton="true"
                      :i18n="frFR"
                      :positionRight="true"
                      :minNights="1"
                      :format="dateFormat"
                      :showYear="true"
                      :showCloseButton="false"
                      :startDate="new Date(new Date().getFullYear(), 0, 1)"
                      :endDate="new Date(Date.now() + 3600 * 1000 * 24)"
                      @check-in-changed="checkInChanged($event)"
                      @check-out-changed="checkOutChanged($event)"
                      @clear-selection="clear($event)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-card>
                <v-card-title>Liste des demandes de transformation de comptes en compte PRO</v-card-title>
                <v-data-table
                  :footer-props="{
                    'items-per-page-options': [5, 10, 15, 30],
                  }"
                  :headers="headers"
                  :items="demandes"
                  :search="search"
                  :server-items-length="totalDemandeCount"
                  :options.sync="options"
                  :loading="loading"
                  class="elevation-1"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td class="d-block d-sm-table-cell">
                        <v-btn @click="redirectToUserDetails(row.item)" text>
                          {{ row.item.userId }}</v-btn
                        >
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.nom }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.prenom }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.activite }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        {{ row.item.date }}
                      </td>
                      <td class="d-block d-sm-table-cell">
                        <v-chip :style="row.item.status.backgroundColor">
                          <span :style="row.item.status.color">
                            {{ row.item.status.text }}
                          </span></v-chip
                        >
                      </td>
                      <td class="d-block d-sm-table-cell">
                        <v-icon
                          style="margin-bottom: 3px; margin-left: 30px"
                          @click="redirectToDemandeDetails(row.item)"
                          >mdi-eye-outline</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import NodesFieldName from "../../utils/nodesFieldName";
import FirebaseDB from "../../utils/firebaseDB";
import FunctionsUtils from "./../../utils/functions";
import moment from "moment";
import HotelDatePicker from "vue-hotel-datepicker2";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
import constants from "./../../utils/constants";
export default {
  name: "ListeDemande",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
    HotelDatePicker,
  },
  data() {
    return {
      frFR: {
        night: "jour",
        nights: "jours",
        "check-in": "",
        "check-out": "Date de la demande",
        "day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],

        tooltip: {
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
        },
      },
      fromDate: new Date("2021-01-01"),
      toDate: new Date(Date.now() + 3600 * 1000 * 24),
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      demandeStatus: [
        {
          name: constants.INVALIDE_LIBELLE,
          value: constants.INVALIDE,
        },
        {
          name: constants.VALIDE_LIBELLE,
          value: constants.VALIDE,
        },
        {
          name: constants.EN_COURS_DE_VALIDATION_LIBELLE,
          value: constants.EN_COURS_DE_VALIDATION,
        },
      ],
      status: null,
      activite: null,
      adresse: null,
      legalStatus: null,
      taxNumber: null,
      userId: null,
      webSite: null,
      totalDemande: [],
      demandes: [],
      totalDemandeCount: 0,
      lastVisibleDemande: null,
      loading: true,
      length: 5,
      search: "",
      options: {},
      statutFiltrage: null,
      sortBy: [],
      infoDemendeur: [],
      searchByName: null,
      dateRange: false,
      demande_id: null,
    };
  },

  methods: {
    clear() {
      this.fromDate = new Date("2022-01-01");
      this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      this.totalDemande = [];
      this.demandes = [];
      this.lastVisibleDemande = null;
      this.dateRange = false;
    },
    checkInChanged(newDate) {
      this.totalDemande = [];
      this.demandes = [];
      this.lastVisibleDemande = null;
      this.fromDate = newDate;
      this.dateRange = true;
      this.getListAccount();
    },
    checkOutChanged(newDate) {
      this.totalDemande = [];
      this.demandes = [];
      this.lastVisibleDemande = null;
      this.toDate = newDate;
      this.getListAccount();
    },
    onStatusChange: function (event) {
      this.statutFiltrage = event;
      this.lastVisibleDemande = null;
      this.totalDemande = [];
      this.getListAccount();
    },
    redirectToDemandeDetails(item) {
      this.$router.push({
        name: "DemandeDetail",
        params: {
          userId: item.userId,
          demandeId: item.demande_id,
          item: item,
        },
      });
    },
    redirectToUserDetails(user) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userId: user.userId,
        },
      });
    },
    async getListAccount() {
      this.loading = true;
      if (!this.fromDate && !this.toDate) {
        this.fromDate = new Date("2022-01-01");
        this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
      }
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var query = db.collection(NodesName.USER_PRO_VALIDATION);

      /* filtre par status de la demande */

      if (this.statutFiltrage === constants.INVALIDE) {
        query = query.where(NodesFieldName.STATUS, "==", constants.INVALIDE);
      } else if (this.statutFiltrage === constants.VALIDE) {
        query = query.where(NodesFieldName.STATUS, "==", constants.VALIDE);
      } else if (this.statutFiltrage === constants.EN_COURS_DE_VALIDATION) {
        query = query.where(
          NodesFieldName.STATUS,
          "==",
          constants.EN_COURS_DE_VALIDATION
        );
      }
      /* Filtre par date de creation de la demande */
      if (this.dateRange === true) {
        query = query
          .where(NodesFieldName.CREATION_DATE, ">=", this.fromDate)
          .where(NodesFieldName.CREATION_DATE, "<=", this.toDate);
      }

      this.totalDemandeCount = await FirebaseDB.getCount(query);

      if (this.totalDemande.length < this.totalDemandeCount) {
        var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
          query,
          this.lastVisibleDemande,
          itemsPerPage,
          this.totalDemandeCount
        );
        let i = 0;

        querySnapshot.forEach((demande) => {
          if (Object.entries(demande.data()).length !== 0) {
            const found = this.totalDemande.some(
              (el) => el.userId === demande.userId
            );

            if (!found) {
              this.totalDemande.push({
                nom: null,
                prenom: null,
                demande_id: demande.data().uniqueId,
                userId: demande.data().userId,
                status: FunctionsUtils.getDemandeState(demande.data().status),
                activite: demande.data().companyActivity,
                adresse: demande.data().companyAdress,
                legalStatus: demande.data().companyLegalStatus,
                taxNumber: demande.data().companyTaxNumber,
                webSite: demande.data().companyWebSite,
                Rna: demande.data().companyRNA,
                cinRecto: demande.data().companyCinRecto,
                cinVerso: demande.data().companyCinVerso,
                patente: demande.data().companyPatente,
                date: moment(
                  new Date(demande.data().creationDate.seconds * 1000)
                ).format("L HH:MM:SS"),
              });

              i++;

              if (querySnapshot.size == i) {
                this.lastVisibleDemande = demande;
              }
            }
          }
        });
      }

      for (var i = 0; i < this.totalDemande.length; i++) {
        this.infoDemendeur[i] = await FirebaseDB.getUserInformationName(
          this.totalDemande[i].userId
        );
        this.totalDemande[i].nom = this.infoDemendeur[i].lastName;
        this.totalDemande[i].prenom = this.infoDemendeur[i].firstName;
      }
      this.demandes = FunctionsUtils.customSortDataTable(
        this.totalDemande,
        this.sortBy,
        sortDesc
      );

      this.demandes = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalDemande,
        itemsPerPage,
        page,
        this.totalDemandesCount
      );

      this.loading = false;
    },
  },
  computed: {
    dateFormat() {
      return "MM/DD/YYYY";
    },
    lastDateAvailable() {
      return this.addYears(new Date(), 2);
    },
    headers() {
      return [
        { text: "ID", value: "userId" },
        { text: "Nom", value: "nom" },
        { text: "Prenom", value: "prenom" },
        { text: "Activité de la company", value: "activite" },
        { text: "Date de la demande", value: "date" },
        { text: "Status de la demande", value: "status" },
        { text: "Détail de la demande", value: "" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getListAccount();
      },
      deep: true,
    },
  },
};
</script>
